<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="تعديل المستودع">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="title"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الاسم</label>
                <b-form-input v-model="name" type="text" id="input-title" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          
            <b-col md="3" xl="3" >
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="code"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الموقع</label>
                <b-form-input v-model="location" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          


            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="employe"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> نوع المستودغ</label>
                <v-select
                  v-model="warehouse_type"
                  label="name"
                  :options="optionsWareHouseType"
                  @input="getWarehouseById(warehouse_type)"
                  :reduce="(val) => val.id"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
    
            <b-col md="3" xl="3" v-if="(warehouse_type==1)">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="employe"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> المستودع</label>
                <v-select
                  v-model="parent_id"
                  label="name"
                  :options="optionsWarehouse"
                  :reduce="(val) => val.id"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="updateWarehouse">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل المستودع</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {


  components: {
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      name: "",
      warehouse_type:'',
      donor_id:'',
      optionsWareHouseType:[{
        id:0,
        name:'رئيسي'
      },
      {
        id:1,
        name:'فرعي'
      }
    
    ],
    parent_id:'',
      location:'',
      optionsWarehouse:[],
      en_name: "",
      donor_account_id:'',
      item_category_id:'',
      
      
      searchTerm: "",
      id: "",
   
    };
  },

  created() {
    this.getWarehouse()

  },

  methods: {
    getWarehouseById(id){
      if(id==1){
      this.optionsWarehouse=[]
      let url=`/api/v1/get-parent-warehouses`
      this.$http.get(url).then((res) => {
        //console.log(res);
  
       this.optionsWarehouse=res.data.data
      });
    }

    },
 
    updateWarehouse() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data =[]
            if(this.warehouse_type==1){
                data = {
      
        name:this.name,
        location:this.location,
        parent_id:this.parent_id
      };
    }
    else{
      data = {
      
      name:this.name,
      location:this.location,
      parent_id:null
    };
    }
          
      this.$http.put(`/api/v1/warehouses/${this.$route.params.id}`, data).then((res) => {
        //console.log(res);
        
        this.$swal({
          title: "",
          text: "تمت تعديل  بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
       
      });
    }})})
    },
    

    getWarehouse() {
      this.$http.get(`/api/v1/warehouses/${this.$route.params.id}`).then((res) => {
        //console.log('warehouses', res)
        this.name=res.data.data.name
         this.location=res.data.data.location
         if(res.data.data.parent!=null){
         
          this.warehouse_type=1
          this.getWarehouseById(1)
          this.parent_id=res.data.data.parent.id
         }
         
         else{
          this.warehouse_type=0
         }
       
   
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>